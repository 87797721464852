<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(isSbPage(blok) ? {} : { id: `${blok.id}` }) }"
    class="relative w-full"
    :threshold="0.5"
    @enter="onIntersect"
  >
    <div class="relative">
      <FimPicture
        v-if="mobile || desktop"
        class="picture"
        provider="storyblok"
        :preload="preloadMedia"
        :loading="loading"
        :strict="hideMobile"
        :mobile="hideMobile ? undefined : mobile"
        :desktop="desktop"
        :focus-mobile="focusMobile"
        :focus-desktop="focusDesktop"
        :alt="alt"
        :layout="layout"
        :widths="widths"
        :sizes="sizes"
        :max-width="maxWidth"
        :ratio-mobile="mobileRatio"
        :ratio-desktop="desktopRatio"
        :object-fit="objectFit"
        :object-position="objectPosition"
      />
      <!-- On-image content -->
      <div
        v-if="showDisclaimer && isSbImage(blok)"
        class="absolute bottom-0 w-full"
      >
        <div
          v-if="blok.warning_text"
          class="w-full px-10 py-5"
          :style="{
            backgroundColor: warningBackgroundColor,
            color: warningTextColor || '#000000',
          }"
        >
          <p class="text-2xl">{{ blok.warning_text }}</p>
        </div>
        <div
          v-if="blok.disclaimer_text && disclaimerPosition === 'on-image'"
          class="w-full px-10 py-5"
          :style="{
            backgroundColor: disclaimerBackgroundColor,
            color: disclaimerTextColor || '#000000',
          }"
        >
          <p class="text-sm">{{ blok.disclaimer_text }}</p>
        </div>
      </div>
    </div>
    <!-- Below-image content -->
    <div
      v-if="
        showDisclaimer &&
        isSbImage(blok) &&
        blok.disclaimer_text &&
        disclaimerPosition === 'below-image'
      "
      class="w-full px-10 py-5"
      :style="{
        backgroundColor: disclaimerBackgroundColor,
        color: disclaimerTextColor || '#000000',
      }"
    >
      <p class="text-sm">{{ blok.disclaimer_text }}</p>
    </div>
  </Intersect>
</template>

<script setup lang="ts">
import { isSbPage, isSbImage, isSbAsset } from '~/utils/typeguards'
import { getFocusPoint } from '../composables/cmsImages'
import type { Sbasset, SbImage, SbPage } from '../types/storyblok'
import type { ObjectFit, ObjectPosition } from '~/constants/ui'

export type CmsImageProps = {
  height?: number
  ratioMobile?: number
  ratioDesktop?: number
  isTeaser?: boolean
  loading?: 'lazy' | 'eager'
  preloadMedia?: boolean
  objectFit?: ObjectFit
  objectPosition?: ObjectPosition
  widths?: Partial<Record<keyof typeof BREAKPOINTS, number>>
  maxWidth?: number // 1400
  sizes?: string
  blok: SbImage | Sbasset | SbPage
}

const props = withDefaults(defineProps<CmsImageProps>(), {
  loading: 'lazy',
  height: undefined,
  ratioMobile: undefined,
  ratioDesktop: undefined,
  objectFit: undefined,
  objectPosition: undefined,
  widths: undefined,
  maxWidth: 1400,
  sizes: undefined,
})

const mobile = computed(() => {
  if (isSbPage(props.blok)) {
    return props.blok?.teaser_image_mobile?.filename
  }

  if (isSbImage(props.blok)) {
    return props.blok?.mobile_image?.filename
  }

  return undefined
})

const desktop = computed(() => {
  if (isSbPage(props.blok)) {
    return props.blok?.teaser_image?.filename
  }

  if (isSbImage(props.blok)) {
    return props.blok?.desktop_image?.filename
  }

  if (isSbAsset(props.blok)) {
    return props.blok.filename
  }

  return undefined
})

const focusMobile = computed(() => {
  if (isSbPage(props.blok)) {
    return getFocusPoint(props.blok.teaser_image_mobile)
  }

  if (isSbImage(props.blok)) {
    return getFocusPoint(props.blok.mobile_image)
  }
  return undefined
})

const focusDesktop = computed(() => {
  if (isSbPage(props.blok)) {
    return getFocusPoint(props.blok.teaser_image)
  }

  if (isSbImage(props.blok)) {
    return getFocusPoint(props.blok.desktop_image)
  }

  if (isSbAsset(props.blok)) {
    return getFocusPoint(props.blok)
  }

  return undefined
})

const alt = computed(() => {
  if (isSbPage(props.blok)) {
    return (
      props.blok?.teaser_image?.alt ??
      props.blok?.teaser_image_mobile?.alt ??
      ''
    )
  }

  if (isSbImage(props.blok)) {
    return props.blok?.desktop_image?.alt ?? props.blok?.mobile_image?.alt ?? ''
  }

  if (isSbAsset(props.blok)) {
    return props.blok?.alt ?? ''
  }

  return undefined
})

const hideMobile = computed(() =>
  isSbImage(props.blok) ? (props.blok?.hide_mobile ?? false) : false,
)

const toNumber = (input: string | number) =>
  typeof input === 'string' ? parseFloat(input) : input

const layout = computed(() =>
  isSbImage(props.blok) ? toPictureLayout(props.blok?.layout, 'raw') : 'raw',
)
const mobileRatio = computed(() => {
  if (props.ratioMobile) {
    return props.ratioMobile
  }
  if (isSbImage(props.blok) && props.blok.mobile_ratio) {
    return toNumber(props.blok.mobile_ratio)
  }

  return undefined
})

const desktopRatio = computed(() => {
  if (props.ratioDesktop) {
    return props.ratioDesktop
  }
  if (isSbImage(props.blok) && props.blok.desktop_ratio) {
    return toNumber(props.blok.desktop_ratio)
  }

  return undefined
})

const { trackPromotionView } = await useTrackingEvents()

const onIntersect = (_: IntersectionObserverEntry, stop: () => void) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}

/* Legal Disclaimer */

const showDisclaimer = computed(() => {
  return isSbImage(props.blok) ? !!props.blok.include_disclaimer : false
})

const disclaimerPosition = computed(
  () => isSbImage(props.blok) && props.blok.disclaimer_position,
)

const warningTextColor = computed(() => {
  return isSbImage(props.blok) && parseColor(props.blok.warning_color)
})

const warningBackgroundColor = computed((): string => {
  if (!isSbImage(props.blok)) return ''
  const color = parseColor(props.blok.warning_background_color) || ''
  return `${color}b3`
})

const disclaimerTextColor = computed(() => {
  return isSbImage(props.blok) && parseColor(props.blok.disclaimer_color)
})

const disclaimerBackgroundColor = computed((): string => {
  if (!isSbImage(props.blok)) return ''
  const color = parseColor(props.blok.disclaimer_background_color) || ''
  return disclaimerPosition.value === 'on-image' ? `${color}b3` : color
})
</script>
